<template>
  <vue-recaptcha
    :sitekey="siteKey"
    @verify="verifyMethod"
    @expired="expiredMethod"
    @render="renderMethod"
    @error="errorMethod"
  ></vue-recaptcha>
</template>

<script>
import { VueRecaptcha } from "vue-recaptcha";
export default {
  name: "Recaptcha",
  components: { VueRecaptcha },
  computed: {
    siteKey() {
      return process.env?.VUE_APP_RECAPTCHA_SITE_KEY;
    },
  },
  methods: {
    errorMethod() {
      console.log("errorMethod");
    },
    renderMethod() {
      return null
    },
    expiredMethod() {
      console.log("expiredMethod");
    },
    verifyMethod() {
      this.$emit("on-captcha-verification");
    },
  },
};
</script>

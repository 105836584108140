<template>
  <div>
    <div v-if="!isMobile" class="hero-image">
      <div class="background"/>
      <div class="content-wrapper white--text">
        <slot name="title"></slot>
        <div id="under-title">
          <slot name="under-title"></slot>
        </div>
      </div>
    </div>
    <div v-if="isMobile" class="hero-image">
      <div class="background" />
      <!-- <div class="page-background-illustration" /> -->
      <div class="content-wrapper-mobile white--text">
        <span style="margin-top: auto; margin-bottom: auto;">
        <slot name="title"></slot>
        <div id="under-title">
          <slot name="under-title"></slot>
        </div>
        </span>
      </div>
    </div>
    <v-container class="pt-sm-12">
      <h2 v-if="title" class="text-center">
        {{ title }}
      </h2>
    </v-container>
  </div>
</template>

<script>
import {gsap} from "gsap";

export default {
  components: {},
  name: "HeroPage",
  props: {
    title: String,
    illustration: {
      type: String,
      default: "",
    },
    animate: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    if (this.animate) {
      const tl = gsap.timeline();
      tl.from(".content-wrapper", {
        opacity: 0,
      }).from("#under-title", {
        opacity: 0,
        y: 50,
      });
      gsap.from(".illustration", {
        scale: 2,
        opacity: 0,
        duration: 1,
        y: -200,
      });
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify?.breakpoint?.mobile;
    }
  }
};
</script>

<style lang="scss" scoped>

h2 {
  margin: 0 auto;
  font-size: 2rem;
  max-width: 50vw !important;

  @media only screen and (max-width: 768px) {
    font-size: 1.75rem;
  }

  &:first-child {
    margin-top: 0;
  }
}
.hero-image {
  position: relative;
  height: 60vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  @media screen and (max-width: 820px) {
    height: 30vh;
  }

  .content-wrapper {
    padding-left: 3rem;
    position: relative;
    z-index: 1;
    max-width: 50ch;

    h1 {
      font-size: 2rem;
    }
  }

  .content-wrapper-mobile {
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 2rem 5%;
    align-items: center;
    text-align: center;
    h1 {
      max-width: 100vw;
      font-size: 1.75rem;
      margin-top: auto;
      margin-bottom: auto;
    }


    @media screen and (max-width: 820px) {
      justify-content: flex-end;
      align-items: unset;
      text-align: unset;
    }
  }

  .background {
    position: absolute;
    background-size: cover !important;
    background-position: top;
    background: url("/img/banner.webp") no-repeat;
    width: 100vw;
    height: 100%;
  }

  .page-background-illustration {
    display: none;
    position: absolute;
    background-size: cover !important;
    background: url("/img/about_banner.webp") no-repeat;
    width: 100vw;
    top: 0;
    z-index: 0;
    opacity: 0.4;
  }

  .illustration {
    position: absolute;
    right: 5%;
    top: 15%;
    max-width: 45vw;
    height: auto;
    z-index: 2;

    img {
      object-fit: contain;
      max-width: 100%;
    }
  }
}

@media (min-width: 600px) {

  h1 {
    font-size: 3.5rem !important;
  }
}

@media only screen and (min-width: 2000px) {
  .hero-image {
    height: 50vh;
  }
}
</style>
